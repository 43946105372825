import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import Modal from 'react-modal';
import fppmLogo from '../../images/migration/fppm-logo.png';
import Close from '../../images/migration/close.svg';
import frCa from '../../translations/fr-CA.json';

const Overlay = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(15, 32, 69, 0.6)',
};

const BodyContainer = {
  display: 'block',
  maxWidth: 648,
  width: '100%',
  boxShadow: '0 0 50px 0 rgba(40, 71, 140, 0.2)',
  top: 'auto',
  left: 'auto',
  right: 'auto',
  bottom: 'auto',
  borderRadius: 12,
};

const Top = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 10,
  paddingBottom: 20,
};

const CloseButton = {
  position: 'absolute',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  right: 0,
  top: 0,
};

const Center = { textAlign: 'center', margin: '0 auto', width: '95%' };

const Attention = {
  color: '#09709c',
  textAlign: 'center',
  fontFamily: 'Gilroy',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
};

const Title = {
  color: '#303333',
  textAlign: 'center',
  fontFamily: 'Gilroy',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '140%',
};

const Text = {
  color: '#545959',
  textAlign: 'center',
  fontFamily: 'Gilroy',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  marginTop: 8,
};

const ButtonContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap-reverse',
  gap: '8px',
  marginTop: 44,
};

const StyledButton = (outline, full) => ({
  width: full ? '95%' : 296,
  height: 48,
  borderRadius: 999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${outline ? '#d0dbdb' : 'transparent'}`,
  backgroundColor: outline ? 'transparent' : '#00544E',
  color: outline ? '#545959' : '#D9F0FA',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  cursor: 'pointer',
});

const StyledButtonLabel = {
  fontFamily: 'Gilroy',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.02em',
  flex: 'none',
  order: 1,
  flexGrow: 0,
  width: '100%',
};

const Button = props => {
  // eslint-disable-next-line react/prop-types
  const { onClick, disable, label, input, outline, full } = props;

  return (
    <>
      <button
        style={StyledButton(outline, full)}
        type="button"
        onClick={onClick}
        disable={disable}
        {...input}
        {...props}
      >
        <span style={StyledButtonLabel}>{label}</span>
      </button>
    </>
  );
};

function FppmPopUp({ open, onClose, noClose }) {
  if (!open) return <></>;

  return (
    <IntlProvider locale="fr" messages={frCa}>
      <Modal isOpen={open} style={{ overlay: Overlay, content: BodyContainer }}>
        <div style={Top}>
          <img src={fppmLogo} alt="FPPM logo" style={{ width: '384px' }} />

          {!noClose && (
            <button
              className="close"
              style={CloseButton}
              onClick={onClose}
              aria-label="modal close button"
              aria-describedby="close-modal-button-desc-for-screen-reader"
              type="button"
            >
              <img src={Close} alt="close modal" />
            </button>
          )}
        </div>

        <div style={Center}>
          <div style={Attention}>
            <FormattedMessage
              id="migration.attention"
              defaultMessage="Attention"
            />
          </div>
          <div style={Title}>
            <FormattedMessage
              id="migration.fppm.news"
              defaultMessage="Important announcement: Improvements to the FPPM health portal"
            />
          </div>
          <div style={Text}>
            <FormattedMessage
              id="migration.fppm.upgraded"
              defaultMessage="We are pleased to announce the upcoming enhancement of the FPPM insurance claims portal.  This enhancement will provide you with a more intuitive, modern and user-friendly experience."
            />
          </div>
          <div style={Text}>
            <FormattedMessage
              id="migration.fppm.update"
              defaultMessage="With this update, all mental health services currently available through Inkblot will be consolidated on this new platform."
            />
          </div>
          <div style={Text}>
            <FormattedMessage
              id="migration.fppm.support"
              defaultMessage="If you have any questions or difficulties, our team will be happy to assist you."
            />
          </div>
        </div>
        <div style={ButtonContainer}>
          <Button
            outline
            label={
              <FormattedMessage
                id="migration.fppm.dependent"
                defaultMessage="Continue with dependent registration"
              />
            }
            onClick={() =>
              window.location.assign('http://app.fppm.qc.ca/dependant')
            }
          />
          <Button
            label={
              <FormattedMessage
                id="migration.fppm.member"
                defaultMessage="Continue with Plan Member registration"
              />
            }
            onClick={() =>
              window.location.assign('http://assurances.fppm.qc.ca/')
            }
          />
        </div>
      </Modal>
    </IntlProvider>
  );
}

FppmPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  noClose: PropTypes.bool,
};

FppmPopUp.defaultProps = {};

export default injectIntl(FppmPopUp);
