let ENV;
let therapyUrl;
let otherTherapyUrl;
let serverUrl;
let otherServerUrl;
let marketingUrl;
let otherMarketingUrl;
let gsplusSignupEnUrl;
let gsplusSignupFrUrl;
let ctry;
let amplitudeApiKey;
let mailchimpUrl;
let assessUrl;
let practiceMarketingUrl;
let embedUrl;

const hostname =
  typeof window !== 'undefined' &&
  window &&
  window.location &&
  window.location.hostname;

if (hostname === 'localhost') {
  ENV = 'DEVELOPMENT';
  ctry = 'CA';
  therapyUrl = 'http://localhost:10101';
  otherTherapyUrl = 'http://localhost:10101';
  serverUrl = 'http://localhost:3000';
  otherServerUrl = 'http://localhost:3000';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  marketingUrl = 'http://localhost:8000';
  assessUrl = 'http://localhost:7070';
  otherMarketingUrl = 'http://localhost:8000';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
  practiceMarketingUrl = 'http://localhost:8100';
  embedUrl = 'http://localhost:10101';
} else if (
  hostname === 'market.inkblottherapy.com' ||
  hostname === 'staging-marketing.inkblottherapy.com'
) {
  ENV = 'STAGING';
  ctry = 'CA';
  therapyUrl = 'https://staging.inkblottherapy.com';
  otherTherapyUrl = 'https://staging.inkblottherapy.com';
  serverUrl = 'https://api.inkblottherapy.com';
  otherServerUrl = 'https://api.inkblottherapy.com';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  marketingUrl = 'https://staging-marketing.inkblottherapy.com';
  assessUrl = 'https://staging.inkblotassess.com';
  otherMarketingUrl = 'https://us-staging-marketing.inkblottherapy.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
  practiceMarketingUrl = 'https://staging-marketing.inkblotpractice.com';
  embedUrl = 'https://mentalhealth-stg.greenshieldplus.ca';
} else if (
  /org.inkblottherapy.com/.test(hostname) ||
  hostname === 'inkblottherapy.com'
) {
  ENV = 'PRODUCTION';
  ctry = 'CA';
  therapyUrl = 'https://app.inkblottherapy.com';
  otherTherapyUrl = 'https://portal.inkblottherapy.com';
  serverUrl = 'https://api.inkblotpractice.com';
  otherServerUrl = 'https://medapi.inkblottherapy.com';
  gsplusSignupEnUrl = 'https://app.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://app.greenshieldplus.ca/fr/sign-up';
  marketingUrl = 'https://org.inkblottherapy.com';
  assessUrl = 'http://inkblotassess.com';
  otherMarketingUrl = 'https://us.inkblottherapy.com';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
  practiceMarketingUrl = 'https://inkblotpractice.com';
  embedUrl = 'https://mentalhealth.greenshieldplus.ca';
} else if (hostname === 'uat-marketing.inkblottherapy.com') {
  ENV = 'UAT';
  ctry = 'CA';
  therapyUrl = 'https://uat.inkblottherapy.com';
  otherTherapyUrl = 'https://uat.inkblottherapy.com';
  serverUrl = 'https://uatapi.inkblottherapy.com';
  otherServerUrl = 'https://uatapi.inkblottherapy.com';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  assessUrl = 'http://uat.inkblotassess.com';
  marketingUrl = 'https://uat-marketing.inkblottherapy.com';
  otherMarketingUrl = 'https://uat-marketing.inkblottherapy.com';
  amplitudeApiKey = null;
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
  practiceMarketingUrl = '#';
} else if (hostname === 'us.inkblottherapy.com') {
  ENV = 'US PROD';
  ctry = 'US';
  therapyUrl = 'https://portal.inkblottherapy.com';
  otherTherapyUrl = 'https://app.inkblottherapy.com';
  serverUrl = 'https://medapi.inkblottherapy.com';
  otherServerUrl = 'https://api.inkblotpractice.com';
  gsplusSignupEnUrl = 'https://app.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://app.greenshieldplus.ca/fr/sign-up';
  marketingUrl = 'https://us.inkblottherapy.com';
  assessUrl = 'http://us.inkblotassess.com';
  otherMarketingUrl = 'https://org.inkblottherapy.com';
  amplitudeApiKey = null;
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
  practiceMarketingUrl = 'https://us.inkblotpractice.com';
} else if (hostname === 'us-staging-marketing.inkblottherapy.com') {
  ENV = 'US DEV';
  ctry = 'US';
  therapyUrl = 'https://us-staging.inkblottherapy.com';
  otherTherapyUrl = 'https://us-staging.inkblottherapy.com';
  serverUrl = 'https://usapi.inkblottherapy.com';
  otherServerUrl = 'https://usapi.inkblottherapy.com';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  marketingUrl = 'https://us-staging-marketing.inkblottherapy.com';
  assessUrl = 'http://us-staging.inkblotassess.com';
  otherMarketingUrl = 'https://staging-marketing.inkblottherapy.com';
  amplitudeApiKey = null;
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
  practiceMarketingUrl = '#';
} else if (hostname === 'demo-marketing.inkblottherapy.com') {
  ENV = 'DEMO';
  ctry = 'CA';
  therapyUrl = 'https://demo.inkblottherapy.com';
  otherTherapyUrl = 'https://demo.inkblottherapy.com';
  serverUrl = 'https://demoapi.inkblottherapy.com';
  otherServerUrl = 'https://demoapi.inkblottherapy.com';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  assessUrl = 'http://demo.inkblotassess.com';
  marketingUrl = 'https://demo-marketing.inkblottherapy.com';
  otherMarketingUrl = 'https://demo-marketing.inkblottherapy.com';
  amplitudeApiKey = null;
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
  practiceMarketingUrl = '#';
} else if (hostname === 'qa1.inkblottherapy.com') {
  ENV = 'QA1';
  ctry = 'CA';
  therapyUrl = 'https://qa1-app.inkblottherapy.com';
  otherTherapyUrl = 'https://qa1-app.inkblottherapy.com';
  serverUrl = 'https://qa1-api.inkblottherapy.com';
  otherServerUrl = 'https://qa1-api.inkblottherapy.com';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  marketingUrl = 'https://qa1.inkblottherapy.com';
  assessUrl = 'https://qa1.inkblotassess.com';
  otherMarketingUrl = 'https://qa1.inkblottherapy.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
  practiceMarketingUrl = 'https://qa1.inkblotpractice.com';
} else if (hostname === 'qa2.inkblottherapy.com') {
  ENV = 'QA2';
  ctry = 'CA';
  therapyUrl = 'https://qa2-app.inkblottherapy.com';
  otherTherapyUrl = 'https://qa2-app.inkblottherapy.com';
  serverUrl = 'https://qa2-api.inkblottherapy.com';
  otherServerUrl = 'https://qa2-api.inkblottherapy.com';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  marketingUrl = 'https://qa2.inkblottherapy.com';
  assessUrl = 'https://qa2.inkblotassess.com';
  otherMarketingUrl = 'https://qa2.inkblottherapy.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
  practiceMarketingUrl = 'https://qa2.inkblotpractice.com';
}

export const COUNTRY = `${ctry}`;
export const ENVIRONMENT = `${ENV}`;
export const THERAPY_URL = `${therapyUrl}`;
export const OTHER_THERAPY_URL = `${otherTherapyUrl}`;
export const SERVER_URL = `${serverUrl}`;
export const OTHER_SERVER_URL = `${otherServerUrl}`;
export const MARKETING_URL = `${marketingUrl}`;
export const ASSESS_URL = `${assessUrl}`;
export const OTHER_MARKETING_URL = `${otherMarketingUrl}`;
export const GSPLUS_SIGNUP_EN_URL = gsplusSignupEnUrl;
export const GSPLUS_SIGNUP_FR_URL = gsplusSignupFrUrl;
export const AMPLITUDE_API_KEY = `${amplitudeApiKey}`;
export const MAILCHIMP_URL = `${mailchimpUrl}`;
export const PRACTICE_MARKETING_URL = `${practiceMarketingUrl}`;
export const EMBED_URL = `${embedUrl}`;
