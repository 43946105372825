import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'gatsby';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import Layout from './layout';
import { EMBED_URL, SERVER_URL, THERAPY_URL } from '../utils/environment';
import '../styles/Pages/forgot_password/styles.scss';
import EyeIcon from './EyeIcon';

import LanguageSelector from './LanguageSelector';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      errorMessage: null,
      successMessage: null,
      visiblePassword: {},
      source: null,
    };
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    this.setState({ source: params.source });
  }

  resetPassword = () => {
    const { reset_password_token } = this.props;
    const { password, passwordConfirmation } = this.state;
    axios
      .put(
        `${SERVER_URL}/api/v2/forgot_passwords/me?reset_password_token=${reset_password_token}`,
        {
          password,
          password_confirmation: passwordConfirmation,
        },
      )
      .then(() => {
        this.setState({
          errorMessage: null,
          successMessage:
            <FormattedMessage
              id="success.passwordSuccessfullyReset"
              defaultMessage="Password successfully reset! Please try logging in with your new credentials."
            />
          ,
        });
      })
      .catch(e => {
        let msg = 'Error';

        if (e.response && e.response.data && e.response.data.message) {
          msg = e.response.data.message;
        } else if (e.response && e.response.statusText) {
          msg = e.response.statusText;
        }

        this.setState({ errorMessage: msg, successMessage: null });
      });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    const { password, passwordConfirmation } = this.state;
    event.preventDefault();
    if (!password) {
      this.setState({
        errorMessage: <FormattedMessage
          id="error.enterPassword"
          defaultMessage="Please enter a password"
        />
      });
      return;
    }
    if (password.length < 8) {
      this.setState({
        errorMessage: <FormattedMessage
          id="error.passwordLength"
          defaultMessage="Password must be at least 8 characters"
        />
      });
      return;
    }
    if (!passwordConfirmation) {
      this.setState({
        errorMessage: <FormattedMessage
          id="error.confirmPassword"
          defaultMessage="Please confirm your password"
        />
      });
      return;
    }
    if (password !== passwordConfirmation) {
      this.setState({
        errorMessage: <FormattedMessage
          id="error.passwordNotMatch"
          defaultMessage="Passwords do not match"
        />
      });
      return;
    }

    this.resetPassword();
  };

  renderPasswordInput = name => {
    const { error, loading, visiblePassword } = this.state;

    return (
      <div className="password-input">
        <div className="text-input">
          <input
            id={name}
            onChange={this.handleChange}
            type={visiblePassword[name] ? 'text' : 'password'}
            name={name}
            value={this.state[name]}
          />
        </div>
        <EyeIcon isVisible={visiblePassword[name]} onClick={() => {
          const visible = { ...visiblePassword };
          visible[name] = !visiblePassword[name];
          this.setState({ visiblePassword: { ...visible } });
        }}
        />
      </div>
    );
  }

  render() {
    const { errorMessage, successMessage } = this.state;
    const { location } = this.props;
    console.log(this.state);
    return (
      <Layout location={location}>
        <div className="forgot-password" role="main">
          <div className="password-container">
            <LanguageSelector style={{ textAlign: 'right', transform: 'translateY(-25px)' }} />
            <h1>
              <FormattedMessage
                id="auth.password"
                defaultMessage="Password"
              />
            </h1>
            <p>
              <FormattedMessage
                id="auth.enterNewPassword"
                defaultMessage="Please enter a new password"
              />
            </p>
            <form onSubmit={this.handleSubmit}>
              <label htmlFor="password">
                <FormattedMessage
                  id="auth.password"
                  defaultMessage="Password"
                />
              </label>
              {this.renderPasswordInput('password')}
              <label htmlFor="passwordConfirmation" style={{ marginTop: 30 }}>
                <FormattedMessage
                  id="auth.confirmPassword"
                  defaultMessage="Confirm Password"
                />
              </label>
              {this.renderPasswordInput('passwordConfirmation')}
              <button disabled={successMessage} type="submit" className="fp-button">
                <FormattedMessage
                  id="auth.resetPassword"
                  defaultMessage="Reset Password"
                />
              </button>
            </form>
            {errorMessage && <p className="error">{errorMessage}</p>}
            {successMessage && (
              <div>
                <p className="success">{successMessage}</p>
                <Link
                  to={
                    this.state.source === 'embed'
                      ? EMBED_URL
                      : `${THERAPY_URL}/signin`
                  }
                >
                  <p style={{ color: '#2e5fca', fontWeight: 600 }}>
                    <FormattedMessage
                      id="auth.signIn"
                      defaultMessage="Sign in"
                    />
                  </p>
                </Link>
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

ResetPassword.propTypes = {
  location: PropTypes.object,
  reset_password_token: PropTypes.string,
};

ResetPassword.defaultProps = {
  location: null,
  reset_password_token: '',
};

export default ResetPassword;
